import React, { Fragment, useId, useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty, noop } from 'lodash';
import { Row, Col } from 'reactstrap';

// Models
import { VehicleVinEntities } from 'client/data/models/vehicle-vin';

// Utils
import { EventToolbox } from 'client/utils/event-toolbox';
import { delayHeavyTask } from 'client/site-modules/shared/utils/inp-utils';

// Constants
import { TrackingConstant } from 'client/tracking/constant';

// Components
import { FieldError } from 'site-modules/shared/components/field-error/field-error';
import { MultipleVinsModal } from 'site-modules/shared/components/appraisal/multiple-vins-modal/multiple-vins-modal';

// Context
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { AppraisalFindVinDrawerContext } from 'site-modules/shared/components/find-vin-modal/appraisal-find-vin-drawer';

import { commonEventData } from './license-plate-tab-container';

import './license-plate-tab-content.scss';

export function LicensePlateTab({
  lpInputSlot,
  stateCodeInputSlot,
  submitButtonSlot,
  overlaySubmitButtonSlot,
  vinFromLicensePlate,
  wasFormSubmitted,
  isSubmitting,
  squishStyles,
  isLpValid,
  setSquishVinError,
  hideVinLicensePlateBtn,
  tabContentModal,
  errorMap,
  creativeId,
  squishVinError,
  componentStyleProperties,
  isEmoSearchEnabled,
  vehicle,
  isAppraisalValuePage,
  setIsMultipleVinProcessed,
  isMultipleVinProcessed,
  onMultipleVinsModalOpen,
  onMultipleVinsModalClose,
  isLicensePlateAndVinStep,
}) {
  const {
    mmy,
    vin,
    setVin,
    licensePlate,
    stateCode,
    setMmy,
    setIsReadyForRedirect,
    isProjectEveStyle,
  } = useAppraisalTabsContext();
  const { setIsInnerModalOpened, isInnerModalOpened } = useContext(AppraisalFindVinDrawerContext);
  const [isMultipleVinsModalOpen, setIsMultipleVinsModalOpen] = useState(isInnerModalOpened || false);

  const uniqInputId = useId();

  const service = vinFromLicensePlate?.service;

  useEffect(() => {
    if (service) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          ...commonEventData,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          subaction_name: TrackingConstant.LICENSE_PLATE_LOOKUP_SERVICE,
          creative_id: creativeId,
          value: service,
        },
      });
    }
  }, [creativeId, service]);

  const vinValueFromLp = vinFromLicensePlate?.vin;

  const vins = vinFromLicensePlate?.vins ?? [];
  const isMultiVins = vins.length > 1;

  useEffect(() => {
    if (!isMultiVins && vinValueFromLp && vin !== vinValueFromLp) {
      setVin(vinValueFromLp);
    }
  }, [isMultiVins, setVin, vin, vinValueFromLp]);

  const hasLicensePlateError = errorMap.has('licensePlate');
  const hasLicensePlateWideError = errorMap.has('licensePlateWide');
  const hasStateCodeError = errorMap.has('stateCode');
  const shouldShowVinError =
    !isSubmitting && !(hasLicensePlateError || hasStateCodeError || hasLicensePlateWideError) && squishVinError;

  const hasSquishStyles = !!squishStyles && squishStyles.length > 0;

  useEffect(() => {
    if (!isMultipleVinProcessed && isMultiVins && isSubmitting && !isMultipleVinsModalOpen) {
      setIsMultipleVinsModalOpen(true);
      setIsInnerModalOpened(true);
    }
  }, [isMultipleVinProcessed, isMultiVins, isSubmitting, isMultipleVinsModalOpen, setIsInnerModalOpened]);

  const isReadyForUpdate =
    !!squishStyles && wasFormSubmitted && isLpValid && !isSubmitting && (!isMultiVins || isMultipleVinProcessed);

  const fireAppraiseVehicleTracking = useCallback(
    (subactionName, value) => {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          ...commonEventData,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          creative_id: creativeId,
          subaction_name: subactionName,
          value,
        },
      });
    },
    [creativeId]
  );

  useEffect(() => {
    if (isReadyForUpdate) {
      if (hasSquishStyles) {
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
          event_data: {
            ...commonEventData,
            action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
            subaction_name: TrackingConstant.SUBACTION_VIN_ENTRY,
            creative_id: creativeId,
            value: vinValueFromLp,
          },
        });

        const { makeNiceId, modelNiceId, year } = squishStyles[0];
        setMmy({ make: makeNiceId, model: modelNiceId, year });
      } else {
        fireAppraiseVehicleTracking(TrackingConstant.SUBACTION_SQUISHVIN_DECODE, TrackingConstant.MMY_NOT_FOUND);
        fireAppraiseVehicleTracking(
          TrackingConstant.SUBACTION_VIN_ERROR,
          `${vin}_${TrackingConstant.SUBACTION_SQUISHVIN_DECODE}_${TrackingConstant.MMY_NOT_FOUND}`
        );

        setSquishVinError();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSquishStyles, isReadyForUpdate]);

  // Make sure all data is set and initiate the redirect
  useEffect(() => {
    if (isReadyForUpdate && !isEmpty(mmy)) {
      delayHeavyTask(() => {
        setIsReadyForRedirect(true);
      });
    }
  }, [isReadyForUpdate, mmy, setIsReadyForRedirect]);

  // Reset multiple VINs modal if LP and/or state code was updated
  useEffect(() => {
    setIsMultipleVinProcessed(false);
  }, [licensePlate, setIsMultipleVinProcessed, stateCode]);

  const {
    FormWrapper,
    licensePlateColSize,
    stateCodeColSize,
    btnWidthMd,
    isVinByAddressOverlay,
    isBackgroundStyleTabEnabled,
    isEscapeHatchDrawer,
    isLocationStyleStep,
    isEscapeHatch,
    isFullWidth,
    isMobile,
    isAddressTab,
  } = componentStyleProperties;

  const closeMultipleVinsModal = useCallback(() => {
    setIsMultipleVinsModalOpen(false);
    setIsInnerModalOpened(false);
  }, [setIsInnerModalOpened]);

  const handleVinFromMultiplesVinsSelect = useCallback(
    selectedVinData => {
      if (!isEmpty(selectedVinData)) {
        const { vin: vinFromMultipleVinsModal } = selectedVinData;
        setVin(vinFromMultipleVinsModal);
      }
      setIsMultipleVinProcessed(true);
      closeMultipleVinsModal();
    },
    [closeMultipleVinsModal, setIsMultipleVinProcessed, setVin]
  );

  return (
    <FormWrapper
      isFullWidth={isFullWidth || isLocationStyleStep || isVinByAddressOverlay}
      isEscapeHatch={isEscapeHatch}
    >
      <div
        className={classnames({
          'd-lg-flex align-items-lg-start': isBackgroundStyleTabEnabled || isProjectEveStyle,
          'w-75': (isBackgroundStyleTabEnabled || isProjectEveStyle) && !isMobile,
        })}
      >
        <Row
          className={classnames('appraise-by-license-plate-tab', {
            'mt-1': isEscapeHatchDrawer,
            'flex-grow-1': (isBackgroundStyleTabEnabled || isProjectEveStyle) && !isEmoSearchEnabled,
            'mr-lg-0_5': isBackgroundStyleTabEnabled || isProjectEveStyle,
          })}
          {...(errorMap.has('licensePlateWide')
            ? { 'aria-describedby': `license-plate-landing-input${uniqInputId}-error`, 'aria-invalid': true }
            : {})}
        >
          <Col {...licensePlateColSize} className={classnames({ 'px-0': isLocationStyleStep })}>
            {lpInputSlot}
          </Col>
          <Col {...stateCodeColSize} className={classnames({ 'px-0': isLocationStyleStep })}>
            {stateCodeInputSlot}
          </Col>
        </Row>
        <div
          className={classnames({
            'mb-2': isVinByAddressOverlay && !isAddressTab,
            ...(isVinByAddressOverlay &&
              !squishVinError && { 'pt-1': !isLicensePlateAndVinStep, 'pt-0_5': isLicensePlateAndVinStep }),
          })}
        >
          {!isAppraisalValuePage && !isVinByAddressOverlay ? (
            submitButtonSlot
          ) : (
            <Fragment>
              {!isMobile && (
                <Row>
                  <Col xs={12} className={classnames({ 'px-0': isLocationStyleStep })}>
                    <FieldError id="vin-errors" error={shouldShowVinError && squishVinError} classes="mb-1" />
                    <FieldError
                      id={`license-plate-landing-input${uniqInputId}-error`}
                      error={errorMap.get('licensePlateWide')}
                      classes="mb-1"
                    />
                  </Col>
                  {isLicensePlateAndVinStep && (
                    <Col xs={12} className="size-14 text-cool-gray-50 mb-1">
                      Your license plate will only be used to look up your car. It will not be stored.
                    </Col>
                  )}
                </Row>
              )}
              {(!hideVinLicensePlateBtn || isVinByAddressOverlay) && (
                <Row
                  className={classnames('align-items-center', {
                    'mt-0_5': isEscapeHatch || isLocationStyleStep || isEscapeHatchDrawer,
                  })}
                >
                  {isLicensePlateAndVinStep && isMobile && (
                    <Col xs={12} className="size-14 text-cool-gray-50 mb-1">
                      Your license plate will only be used to look up your car. It will not be stored.
                    </Col>
                  )}
                  <Col
                    xs={12}
                    md={isEscapeHatch || isVinByAddressOverlay ? 12 : btnWidthMd}
                    className={classnames({
                      'px-0': isLocationStyleStep,
                      'mt-1': isLicensePlateAndVinStep && isAddressTab && isVinByAddressOverlay,
                    })}
                  >
                    {overlaySubmitButtonSlot}
                    {isMobile && (
                      <Fragment>
                        <FieldError id="vin-errors" error={shouldShowVinError && squishVinError} classes="mt-0_5" />
                        <FieldError
                          id={`license-plate-landing-input${uniqInputId}-error`}
                          error={errorMap.get('licensePlateWide')}
                          classes="mt-0_5"
                        />
                      </Fragment>
                    )}
                  </Col>
                  {tabContentModal && (
                    <Col
                      xs={12}
                      md={isEscapeHatch ? 12 : 6}
                      className={classnames('mt-1 text-center', {
                        'text-md-start mt-md-0': !isEscapeHatch,
                      })}
                    >
                      {tabContentModal}
                    </Col>
                  )}
                </Row>
              )}
            </Fragment>
          )}
        </div>
      </div>
      {!isAppraisalValuePage && !isVinByAddressOverlay && (
        <FieldError
          id={`license-plate-landing-input${uniqInputId}-error`}
          error={errorMap.get('licensePlateWide')}
          classes={classnames('mt-1', {
            'license-plate-tab-content-verbose-error mt-lg-0': isBackgroundStyleTabEnabled,
          })}
        />
      )}
      <MultipleVinsModal
        isOpen={isMultipleVinsModalOpen}
        toggle={handleVinFromMultiplesVinsSelect}
        vins={vins}
        onSelectVin={handleVinFromMultiplesVinsSelect}
        vehicle={vehicle}
        isAppraisalValuePage={isAppraisalValuePage}
        onOpen={onMultipleVinsModalOpen}
        onClose={onMultipleVinsModalClose}
      />
    </FormWrapper>
  );
}

LicensePlateTab.propTypes = {
  lpInputSlot: PropTypes.node,
  stateCodeInputSlot: PropTypes.node,
  submitButtonSlot: PropTypes.node,
  overlaySubmitButtonSlot: PropTypes.node,
  vinFromLicensePlate: PropTypes.shape({}),
  wasFormSubmitted: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  squishStyles: VehicleVinEntities.SquishStyles,
  isLpValid: PropTypes.bool,
  setSquishVinError: PropTypes.func.isRequired,
  hideVinLicensePlateBtn: PropTypes.bool,
  tabContentModal: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  errorMap: PropTypes.shape({}),
  creativeId: PropTypes.string,
  squishVinError: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  componentStyleProperties: PropTypes.shape({}),
  isEmoSearchEnabled: PropTypes.bool,
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      slug: PropTypes.string,
    }),
    model: PropTypes.shape({
      slug: PropTypes.string,
    }),
    year: PropTypes.shape({
      year: PropTypes.string,
    }),
  }),
  isAppraisalValuePage: PropTypes.bool,
  setIsMultipleVinProcessed: PropTypes.func.isRequired,
  onMultipleVinsModalOpen: PropTypes.func,
  onMultipleVinsModalClose: PropTypes.func,
  isMultipleVinProcessed: PropTypes.bool,
  isLicensePlateAndVinStep: PropTypes.bool,
};

LicensePlateTab.defaultProps = {
  lpInputSlot: null,
  stateCodeInputSlot: null,
  submitButtonSlot: null,
  overlaySubmitButtonSlot: null,
  vinFromLicensePlate: null,
  wasFormSubmitted: false,
  isSubmitting: false,
  squishStyles: null,
  isLpValid: false,
  hideVinLicensePlateBtn: false,
  tabContentModal: null,
  errorMap: new Map(),
  creativeId: '',
  squishVinError: '',
  componentStyleProperties: {},
  isEmoSearchEnabled: false,
  vehicle: null,
  isAppraisalValuePage: false,
  onMultipleVinsModalOpen: noop,
  onMultipleVinsModalClose: noop,
  isMultipleVinProcessed: false,
  isLicensePlateAndVinStep: false,
};
