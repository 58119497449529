import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input, FormGroup, Label } from 'reactstrap';
import { randomInt } from 'client/utils/seed-randomizers';
import { FieldError } from 'site-modules/shared/components/field-error/field-error';

const LICENSE_PLATE_MAX_LENGTH = 9;

export function LicensePlateTabLpInput({
  licensePlateInputValue,
  onLicensePlateChange,
  errorMap,
  isLocationStyleStep,
  isVinByAddressOverlay,
  isEval4351Chal,
  isAppraisalValuePage,
  isEscapeHatch,
  isFontSize12,
  isFontSize16,
  isAddressTab,
}) {
  const uniqInputId = useRef(randomInt());

  const licensePlateInputName = 'license-plate';
  const licensePlateInputId = `${licensePlateInputName}${uniqInputId.current}`;
  const hasLicensePlateError = errorMap.has('licensePlate');

  return (
    <FormGroup
      className={classnames('styled-input', {
        'has-danger': hasLicensePlateError,
        'pr-md-0_75 pr-lg-1': isLocationStyleStep,
        'text-start': isVinByAddressOverlay,
        'mb-0_5': isEval4351Chal,
      })}
    >
      <Label
        className={classnames({
          'fw-bold': isAppraisalValuePage && !isEscapeHatch && !isLocationStyleStep && !isAddressTab,
          'size-12': isFontSize12,
          'size-14 text-gray-darker': isEscapeHatch,
          'size-16 text-start fw-bold': isVinByAddressOverlay && !isAddressTab,
        })}
        for={licensePlateInputId}
      >
        License Plate
      </Label>
      <div className="input-wrapper pos-r">
        <Input
          className={classnames('license-plate-input', {
            'size-16': isFontSize16,
            'size-14': !isFontSize16,
          })}
          id={licensePlateInputId}
          name={licensePlateInputName}
          value={licensePlateInputValue}
          onChange={onLicensePlateChange}
          placeholder={isEscapeHatch ? '' : 'Enter License Plate'}
          maxLength={LICENSE_PLATE_MAX_LENGTH}
          {...(hasLicensePlateError
            ? { 'aria-describedby': `${licensePlateInputId}-error`, 'aria-invalid': true }
            : {})}
          aria-required
          required
        />
      </div>
      <FieldError
        id={`${licensePlateInputId}-error`}
        error={errorMap.get('licensePlate')}
        isLiveRegion
        classes="mt-0_5"
      />
    </FormGroup>
  );
}

LicensePlateTabLpInput.propTypes = {
  licensePlateInputValue: PropTypes.string,
  onLicensePlateChange: PropTypes.func.isRequired,
  errorMap: PropTypes.shape({}),
  isLocationStyleStep: PropTypes.bool,
  isVinByAddressOverlay: PropTypes.bool,
  isEval4351Chal: PropTypes.bool,
  isAppraisalValuePage: PropTypes.bool,
  isEscapeHatch: PropTypes.bool,
  isFontSize12: PropTypes.bool,
  isFontSize16: PropTypes.bool,
};

LicensePlateTabLpInput.defaultProps = {
  licensePlateInputValue: '',
  errorMap: new Map(),
  isLocationStyleStep: false,
  isVinByAddressOverlay: false,
  isEval4351Chal: false,
  isAppraisalValuePage: false,
  isEscapeHatch: false,
  isFontSize12: false,
  isFontSize16: false,
};
